@import "~antd/dist/antd.css";

.gh-link {
	color: inherit;
}

.credit-sep {
	padding: 0 10px;
}

.user-container {
	padding: 1em;
	height: 100%;
	width: 100%;
}

.user-welcome {
	font-size: 4em;
	margin-right: 10vh;
}

.user-flex {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	align-items: stretch;
	margin-top: 2em;
}

.user-flexSide {
	height: 100%;
	width: 100%;
}

.user-flexLeft {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	align-self: stretch;
}

.user-welcomeEmoji {
	margin-left: 10px;
}

.user-leftSection {
	margin-right: 2em;
	margin-bottom: 1em;
	background-color: white;
	border-radius: 3em;
	padding: 2em;
}

.user-flexRight {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: stretch;
}

.user-classItem {
	width: 100%;
	margin-bottom: 1em;
	border-radius: 3em;
	cursor: pointer;
	padding: 1em;
	text-align: center;
	vertical-align: middle;
	transition: transform 0.15s ease;
}
.user-classItem:hover {
	/* transform: scale(1.01); */
	filter: drop-shadow(0 0 0.5vh #9f9f9f);
}

.user-classItem h3 {
	font-size: 1.5em;
}
.user-classItem p {
	font-size: 1.1em;
}

.user-leftSection-titleContainer {
	margin-bottom: 1em;
}

.user-leftSection-title {
	font-size: 2em;
	display: inline;
	margin-right: 0.5em;
}
.user-leftSection-title-upcomingPeriod {
	display: inline;
}

.user-joinNowButton {
	border-radius: 3em;
	height: 100%;
	width: 100%;
}

.user-joinNowButton > p {
	font-size: 1.5em;
	margin: 0.5em;
}

.user-joinNowButton > p > img {
	width: 1.5em;
	margin-right: 10px;
}

.FAB {
	position: absolute;
	top: 4vh;
	right: 4vh;
	border-radius: 100%;
	transition: transform 0.15s ease;
}
.FAB:hover {
	transform: scale(1.1);
}

.FAB img {
	width: 7vh;
	height: 7vh;
	min-width: 70px;
	min-height: 70px;
	filter: drop-shadow(0.2vh 0.2vh 0.5vh #9f9f9f);
}

code {
	font-family: "Courier New", monospace;
	background-color: #f0f0f0;
	padding: 2px 4px;
	border-radius: 0.2em;
	word-break: break-all;
}

.upcoming-noClass {
	text-align: center;
	padding-right: 1vw;
	margin-top: 2em;
}
.upcoming-noClass img {
	width: 20vw;
	margin-bottom: 2em;
}

@media only screen and (max-width: 920px) {
	.user-flex {
		flex-wrap: wrap;
	}
	.upcoming-noClass > img {
		width: 100%;
	}
}
